
$(function () {
	var topBtn = $(".move");
	topBtn.hide();
	//スクロールが100に達したらボタン表示
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			topBtn.fadeIn();
		} else {
			topBtn.fadeOut();
		}
	});
	//スクロールしてトップ
	topBtn.click(function () {
		$("body,html").animate({
			scrollTop: 0
		}, 500);
		return false;
	});
});


var target = "blank";
function jump(id) {

	var url = document.getElementById(id).select.options[document.getElementById(id).select.selectedIndex].value;

	if (url != "") {

		if (target == "top") {

			top.location.href = url;

		}
		else if (target == "blank") {

			window.open(url, "window_name");

		}
		else if (target != "") {

			eval("parent." + target + ".location.href = url");

		}
		else {

			location.href = url;

		}

	}

}

function onclick(event) {
	jump('form2')
}